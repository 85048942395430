import React from "react"
import { graphql } from "gatsby"
import "normalize.css"

import Layout from "../components/page/Layout"
import SEO from "../components/page/Seo"

import StandardPadding from "../components/atoms/StandardPadding"
import { Title, Paragraph } from "../components/atoms/Typography"
import Button from "../components/atoms/Button"

const NotFoundPage = ({ data }) => {
  const site = data.strapi.site
  return (
    <Layout site={site}>
      <SEO
        socialShare={site.socialShareImage}
        favicon={site.favicon}
        metaDescription={site.siteDescription}
        siteTitle={site.title}
        title="404: Not Found"
      />
      <StandardPadding>
        <Title variant="h1">404: NOT FOUND</Title>
        <Paragraph>You just hit a route that doesn&#39;t exist...</Paragraph>
        <Button link={{ to: "/" }} text="Go to Home" variant="primary" mt={6} />
      </StandardPadding>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage($siteId: ID!) {
    strapi {
      site(id: $siteId) {
        ...Site
      }
    }
  }
`
